<template>
  <v-container>
    <v-card class="pa-4">
      <v-card-title class="text-h5">Submission Details</v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <table class="submission-table">
            <tbody>
              <tr>
                <td class="table-key">Brand Name</td>
                <td>{{ submissionData.brandName }}</td>
              </tr>
              <tr>
                <td class="table-key">Pack Size</td>
                <td>{{ submissionData.packSize }} ml</td>
              </tr>
              <tr>
                <td class="table-key">Coordinates</td>
                <td>{{ coordinates }}</td>
              </tr>
              <tr>
                <td class="table-key">Price</td>
                <td>{{ submissionData.price }}</td>
              </tr>
              <tr>
                <td class="table-key">Reason</td>
                <td>{{ submissionData.reason }}</td>
              </tr>
              <tr>
                <td class="table-key">Drink Where</td>
                <td>{{ submissionData.drinkWhere }}</td>
              </tr>
              <tr>
                <td class="table-key">Quantity Bought</td>
                <td>{{ submissionData.quantityBought }}</td>
              </tr>
              <tr>
                <td class="table-key">Quantity Drank</td>
                <td>{{ submissionData.quantityDrank }}</td>
              </tr>
              <tr>
                <td class="table-key">Promotion</td>
                <td>{{ submissionData.promotion }}</td>
              </tr>
              <tr>
                <td class="table-key">Occasion</td>
                <td>{{ submissionData.occasion }}</td>
              </tr>
              <tr>
                <td class="table-key">Company</td>
                <td>{{ submissionData.company }}</td>
              </tr>
              <tr>
                <td class="table-key">Purchase Type</td>
                <td>{{ submissionData.purchaseType }}</td>
              </tr>
              <tr>
                <td class="table-key">Project Code</td>
                <td>{{ submissionData.projectCode }}</td>
              </tr>
              <tr>
                <td class="table-key">Submission Date</td>
                <td>
                  {{ new Date(submissionData.createdAt).toLocaleString() }}
                </td>
              </tr>
              <tr>
                <td class="table-key">Shop Image</td>
                <td>
                  <v-img
                    :src="submissionData.shopImage"
                    max-width="300"
                    max-height="250"
                    contain
                  ></v-img>
                </td>
              </tr>
              <tr>
                <td class="table-key">Receipt Image</td>
                <td>
                  <v-img
                    :src="submissionData.receiptImage"
                    max-width="300"
                    max-height="250"
                    contain
                  ></v-img>
                </td>
              </tr>
              <tr>
                <td class="table-key">Beverage Image</td>
                <td>
                  <v-img
                    :src="submissionData.beverageImage"
                    max-width="300"
                    max-height="250"
                    contain
                  ></v-img>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2.js";
const service = new RestResource();

export default {
  data() {
    return {
      submissionData: {},
    };
  },

  computed: {
    coordinates() {
      return this.submissionData.location
        ? this.submissionData.location.coordinates.join(", ")
        : "";
    },
  },

  mounted() {
    this.getSubmissionData();
  },

  methods: {
    async getSubmissionData() {
      this.$setLoader();
      const response = await service.getSubmissionData({
        submissionId: this.$route.params.submissionId,
      });
      if (response && response.data) {
        this.submissionData = response.data.data;
      }
      this.$disableLoader();
    },
  },
};
</script>

`
<style scoped>
.submission-table {
  width: 100%;
  border-collapse: collapse;
}
.table-key {
  font-weight: bold;
  width: 30%;
  background-color: #f5f5f5;
  padding: 16px;
}
.submission-table td {
  padding: 16px;
  border-bottom: 1px solid #ddd;
  min-height: 60px;
}
.submission-table td img {
  width: 200px;
  height: 150px;
}
</style>
